import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * @returns The highest breakpoint value on the current view.
 */
const useBreakpointWidth = () => {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
};

export default useBreakpointWidth;

import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useOfferContext } from '../../context/offerContext';
import { useFeatures, testNames } from '../../context/features';
import { submitQuoteRequestSSE } from '../../api/submit';
import { offerRequestPrepared, offerAvailable } from '../../utils/offerEvents';
import { mileageToInt } from '../../utils/format';
import { getCookieValue } from '../../utils/cookie';
import useTriggerIneligibleEvent from './useTriggerIneligibleEvent';
import { CLIENT_INELIGIBLE_ERRORS } from '../../constants';
import { useCustomerInfo } from '../../context/customerInfo';
import useRetryAsyncRequest from '../../hooks/useRetryAsyncRequest';
import { useFormContext } from '../../context/formContext';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { IOffer, IContinuation, ISubmitQuoteResponse, IOfferResponse } from '../../types/IOfferResponse';
import { getQuoteV2 } from '../../api/quote';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AxiosResponse } from 'axios';

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
const DEFAULT_OFFER_DELAY_IN_MS = 2000;
const GET_QUOTE_TIMEOUT_IN_MS = 20000;

interface ITriggerStartQuote {
    getIneligiblePageAnalyticsValue: (offerResponse: IOffer) => string;
}

interface IShoppingData {
    timestamp: number;
}

export default function useRequestOffer() {
    const appInsights = useAppInsightsContext();
    const { vehicleInfo, vehicleConditionInfo, featureInfo, featureLookupInfo } = useVehicleInfo();
    const { customerInfo } = useCustomerInfo();
    const { retry } = useRetryAsyncRequest();
    const { formMetadata } = useFormContext();
    const { trigger: vehicleIneligible } = useTriggerIneligibleEvent();
    const { setIneligibleReason, setOffer, setKbb, saveResult, quoteId } = useOfferContext();
    const { isFeatureEnabled, icoFeatures, clientFeatures } = useFeatures();
    const timeoutRef = useRef(null);

    const shoppingSignalWithin60Days = (key: string): boolean => {
        let res = false;
        const item = window.localStorage.getItem(key);
        if (item) {
            const itemData: IShoppingData[] = JSON.parse(item);
            if (itemData.length > 0) {
                if (Math.abs(new Date().getTime() - itemData[0].timestamp) / (24 * 60 * 60 * 1000) <= 60) {
                    res = true;
                }
            }
        }
        return res;
    };

    const startSSE = useCallback(
        async ({ getIneligiblePageAnalyticsValue }: ITriggerStartQuote): Promise<IOffer | null> => {
            // Offer request is ready
            const intMileage = mileageToInt(vehicleConditionInfo.mileage);
            offerRequestPrepared(vehicleInfo, intMileage);

            //duplicated
            const triggerIneligible = (continuation?: IContinuation, reason?: string, offerRes?: IOffer) =>
                vehicleIneligible({
                    isSecondIneligible: true,
                    mileage: intMileage,
                    continuation,
                    reason,
                    pageType: getIneligiblePageAnalyticsValue(offerRes),
                    isPicsyEligible: offerRes?.isPicsyEligible || false,
                });
            const handleQuoteResponse = async (offerResponse: IOfferResponse) => {
                appInsights.trackEvent({
                    name: 'async fetch: quote received',
                    properties: {
                        quoteId,
                        timestamp: new Date().toISOString(),
                    },
                });

                const quote = offerResponse?.offer;

                if (quote?.valuation > 0) {
                    setOffer(quote);
                    setKbb(offerResponse.kelleyBlueBookValue);
                    offerAvailable(saveResult, vehicleInfo, intMileage, quote, offerResponse.kelleyBlueBookValue);

                    appInsights.trackEvent({
                        name: 'async fetch: valuation received',
                        properties: {
                            quoteId,
                            timestamp: new Date().toISOString(),
                        },
                    });
                } else if (quote) {
                    const declineReason = quote?.declineReason;
                    setIneligibleReason(declineReason);
                    setOffer(quote);

                    triggerIneligible(null, declineReason, quote);
                } else {
                    triggerIneligible(null, CLIENT_INELIGIBLE_ERRORS.errorCallingQuoteRequest, quote);
                }
            };

            const trackValueEnabled = isFeatureEnabled(testNames.TRACK_VALUE);

            try {
                const getOfferResponse = async (): Promise<AxiosResponse> => {
                    const response = await submitQuoteRequestSSE(quoteId, {
                        ciamId: getCookieValue('KmxMyKmx_0', 'userid'),
                        visitorId: getCookieValue('KmxVisitor_0', 'VisitorID'),
                        vin: vehicleInfo.vin,
                        mileage: parseInt(vehicleConditionInfo.mileage.replace(/,/g, '')),
                        styleCode: featureInfo.style.id,
                        zipCode: vehicleInfo.zipcode,
                        transmission: featureInfo.transmission,
                        drive: featureInfo.drive,
                        standardOptions: featureInfo.standardOptions,
                        availableOptions: featureInfo.availableOptions,
                        conditionQuestions: vehicleConditionInfo.conditionAnswers.filter(x => x.answers.length > 0),
                        metadata: {
                            emailAddress: customerInfo.email,
                            'condition-capture-override': formMetadata['condition-capture-override'],
                            startingMethod: formMetadata.startingMethod,
                            originPage: formMetadata.originPage,
                            querystring: formMetadata.querystring,
                            enabledFeatures: icoFeatures.join(),
                            hasViewedVehicleWithin60Days: shoppingSignalWithin60Days('viewedVehicles').toString(),
                            hasViewedSearchResultsWithin60Days:
                                shoppingSignalWithin60Days('kmx-recent-searches').toString(),
                            ...(trackValueEnabled && {
                                marketValueEmailOptIn: formMetadata.marketValueEmailOptIn.toString(),
                            }),
                            sellingOrTrading: customerInfo.sellingOrTrading,
                            clientFeatures: clientFeatures.join(),
                        },
                    });
                    return response;
                };

                const response = await retry({ asyncFn: getOfferResponse });
                if (!response || response.status !== 202) {
                    appInsights.trackException({
                        exception: new Error('async submit: failure'),
                        properties: {
                            response: response,
                            quoteId,
                        },
                    });
                    throw "quote didn't submit properly";
                }
                const offerResponse: ISubmitQuoteResponse = response.data;

                await sleep(
                    offerResponse.connectionDelayInMilliseconds > 0
                        ? offerResponse.connectionDelayInMilliseconds
                        : DEFAULT_OFFER_DELAY_IN_MS
                );

                const handleGetQuote = async () => {
                    clearTimeout(timeoutRef.current);
                    // for v2 this will need to call /v2/quotes/async(quoteId)
                    // this will be a separate endpoint just for the GET associated with
                    // completing an async transaction. This one call here.
                    try {
                        const quote = await retry({ asyncFn: () => getQuoteV2(quoteId, true) });
                        await handleQuoteResponse(quote?.data);
                    } catch (err) {
                        appInsights.trackException({
                            exception: new Error('async fetch: failure'),
                            properties: {
                                quoteId,
                            },
                        });
                        console.log('caught error', err);
                        triggerIneligible(null, CLIENT_INELIGIBLE_ERRORS.errorClientSide, null);
                    }
                };

                const source = new EventSource(
                    `${process.env.AG_URL}api/quotes/listen/${quoteId}?token=${offerResponse.token}`
                );
                source.addEventListener('ready', () => {
                    source.close();
                    appInsights.trackEvent({
                        name: 'event stream: ready',
                        properties: {
                            quoteId,
                            timestamp: new Date().toISOString(),
                        },
                    });
                    handleGetQuote();
                });
                source.addEventListener('timeout', () => {
                    source.close();
                    appInsights.trackEvent({
                        name: 'event stream: timeout',
                        properties: {
                            quoteId,
                            timestamp: new Date().toISOString(),
                        },
                    });
                    handleGetQuote();
                });
                source.onerror = (e: MessageEvent) => {
                    source.close();
                    appInsights.trackException({
                        exception: new Error('event stream: error'),
                        properties: {
                            event: e,
                            quoteId,
                        },
                    });
                    handleGetQuote();
                };

                timeoutRef.current = setTimeout(async () => {
                    try {
                        const quote = await retry({ asyncFn: () => getQuoteV2(quoteId, true) });
                        if (quote?.data?.offer?.metaData?.disposition === 'pending') {
                            // Let SSE keep going
                            return;
                        } else {
                            source.close();
                            await handleQuoteResponse(quote?.data);
                        }
                    } catch (err) {
                        appInsights.trackException({
                            exception: new Error('async fetch: failure'),
                            properties: {
                                quoteId,
                            },
                        });
                        console.log('caught error', err);
                        triggerIneligible(null, CLIENT_INELIGIBLE_ERRORS.errorClientSide, null);
                    }
                }, GET_QUOTE_TIMEOUT_IN_MS);
            } catch (err) {
                console.log('caught error', err);
                triggerIneligible(null, CLIENT_INELIGIBLE_ERRORS.errorClientSide, null);
            }
            return null;
        },
        [
            customerInfo,
            featureInfo,
            featureLookupInfo,
            formMetadata,
            quoteId,
            retry,
            saveResult,
            setIneligibleReason,
            setOffer,
            vehicleConditionInfo,
            vehicleIneligible,
            vehicleInfo,
            isFeatureEnabled,
            icoFeatures,
            clientFeatures,
        ]
    );

    // Cleanup any remaining timeout
    useEffect(() => {
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    });

    return useMemo(() => ({ startSSE }), [startSSE]);
}

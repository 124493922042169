export const KMX_COOKIE_ID = 'KmxMyKmx_0';

export const getCookieValue = (cookieName: string, propertyName: string): string => {
    return (
        document.cookie
            .split('; ')
            .find(row => row.startsWith(cookieName))
            ?.split('=')
            .slice(1)
            .join('=')
            .split('&')
            .find(x => x.startsWith(propertyName))
            ?.split('=')[1] || null
    );
};

export function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts?.length === 2) return parts.pop()?.split(';')?.shift();
    // eslint-disable-next-line consistent-return
    return;
}

export const isLoggedIn = (): boolean => {
    const myKmxSession = getCookie(KMX_COOKIE_ID);

    return Boolean(myKmxSession);
};

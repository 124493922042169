import { useCallback, useMemo } from 'react';
import { useOfferContext } from '../../context/offerContext';
import { useStoreInfo } from '../../context/storeInfo';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { IContinuation } from '../../types/IOfferResponse';
import { storeAdditionalCarOffer } from '../../utils/localStorage';
import { defaultIneligibleParams, vehicleIneligible } from '../../utils/offerEvents';

interface IVehicleIneligibleParams {
    isSecondIneligible?: boolean;
    mileage?: number;
    continuation?: IContinuation;
    reason?: string;
    pageType?: string;
    isPicsyEligible?: boolean;
    picsyUrl?: string;
}

interface IUseVehicleIneligible {
    onIneligible?: () => void;
}

export default function useTriggerIneligibleEvent({ onIneligible }: IUseVehicleIneligible = {}) {
    const { handleIneligible, quoteId, saveResult, setIneligible } = useOfferContext();
    const { storeInfo } = useStoreInfo();
    const { vehicleInfo } = useVehicleInfo();

    const trigger = useCallback(
        (params: IVehicleIneligibleParams) => {
            const parameter = {
                ...defaultIneligibleParams,
                saveResult,
                quoteId,
                vehicleInfo,
                ...params,
            };

            vehicleIneligible(parameter);

            if (quoteId && saveResult) {
                storeAdditionalCarOffer({
                    createdDateUtc: new Date(),
                    expirationDateUtc: new Date(new Date().setDate(new Date().getDate() + 7)),
                    isPicsyEligible: parameter.isPicsyEligible ? true : false,
                    isStoreAppraisal: false,
                    offerAmount: 0,
                    offerCode: quoteId,
                    offerType: 'ineligible',
                    picsyUrl: params.picsyUrl,
                    storeId: storeInfo.id,
                    vehicleInformation: {
                        vin: vehicleInfo.vin,
                        mileage: vehicleInfo.mileage,
                        profile: {
                            year: parseInt(vehicleInfo.profile.year),
                            make: vehicleInfo.profile.make,
                            model: vehicleInfo.profile.model,
                            trim: vehicleInfo.profile.trim,
                            makeCode: vehicleInfo.profile.makeCode,
                            modelCode: vehicleInfo.profile.modelCode,
                            trimCode: vehicleInfo.profile.trimCode,
                        },
                    },
                    vin: vehicleInfo.vin,
                    zipCode: vehicleInfo.zipcode,
                });
            }

            if (handleIneligible) {
                setIneligible(true);
            }

            if (typeof onIneligible === 'function') {
                onIneligible();
            }
        },
        [saveResult, quoteId, vehicleInfo, setIneligible, onIneligible, handleIneligible]
    );

    return useMemo(() => ({ trigger }), [trigger]);
}

import * as React from 'react';
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
    IConditionQuestion,
    IConditionAnswer,
    AdditionalConditionQuestionContentType,
    IDynamicConditionAnswer,
} from '../../../types/IConditionQuestion';
import Card from '../../Card';
import ValidatedArea from '../../ValidatedArea';
import { QUESTION_KBB_CONDITION } from '../../../types/ConditionQuestions';
import { useVehicleInfo } from '../../../context/vehicleInfo';

import * as styles from './ConditionsBody.module.scss';

interface ICardQuestion {
    readOnly: boolean;
    setShowKbbDialog: (s: boolean) => void;
    includeKbbConditionQuestion: boolean;
    conditionValidationTriggered: boolean;
    presetAnswer: string;
}

const KBB_ANSWER = {
    id: 1020,
    answers: [] as number[],
};

const CardQuestion: React.FC<ICardQuestion> = ({
    readOnly,
    conditionValidationTriggered,
    includeKbbConditionQuestion,
    setShowKbbDialog,
    presetAnswer,
}) => {
    const conditionQuestion = QUESTION_KBB_CONDITION;
    const { setVehicleConditionInfo } = useVehicleInfo();
    const [cardState, setCardState] = useState(conditionQuestion);

    const updateAnswers = React.useCallback(
        (answerId: number) => {
            const answer = KBB_ANSWER;
            answer.answers = [answerId];

            const questionCode = 1020;
            const getConditionAnswers = (conditionAnswers: IDynamicConditionAnswer[]) => {
                if (!conditionAnswers.find(x => x.id === questionCode)) {
                    return [...conditionAnswers, answer];
                } else {
                    return conditionAnswers.map(x => (x.id === questionCode ? answer : x));
                }
            };

            setVehicleConditionInfo(prev => ({
                ...prev,
                conditionAnswers: getConditionAnswers(prev.conditionAnswers),
            }));
        },
        [includeKbbConditionQuestion, setVehicleConditionInfo]
    );

    useEffect(() => {
        if (includeKbbConditionQuestion) {
            updateAnswers(conditionQuestion.selectedAnswerId);
        }
        if (presetAnswer) {
            clickCard(
                conditionQuestion.answers.find(x => presetAnswer === x.answer)?.answerId ||
                    conditionQuestion.selectedAnswerId
            );
        }
    }, [presetAnswer]);

    const clickCard = (answerId: number) => {
        setCardState(prev => ({
            ...prev,
            selectedAnswerId: answerId,
        }));

        updateAnswers(answerId);
    };

    const getAdditionalQuestionTemplateContent = (arg: IConditionQuestion) => {
        switch (arg.additionalContentType) {
            case AdditionalConditionQuestionContentType.KbbCondition:
                return (
                    <a
                        onClick={() => {
                            setShowKbbDialog(true);
                            return false;
                        }}
                        className={styles.kbbDialogLink}
                    >
                        View full Kelley Blue Book&reg; condition definitions
                    </a>
                );
            default:
                return undefined;
        }
    };

    const choices = conditionQuestion.answers.map((answer: IConditionAnswer) => (
        <Card
            key={answer.answerId}
            onClick={() => clickCard(answer.answerId)}
            header={answer.answer}
            content={answer.subText}
            footer={answer.note}
            selected={answer.answerId === cardState?.selectedAnswerId}
            disabled={readOnly}
        />
    ));

    return (
        <>
            <h3 className="kmx-typography--headline-2">Condition</h3>
            <fieldset id={'question-' + conditionQuestion.questionId} className={'kmx-flex-wrapper'}>
                <ValidatedArea
                    overlayIcon={false}
                    invalid={conditionValidationTriggered && !conditionQuestion.selectedAnswerId}
                    validationMessage="Please make a selection below"
                >
                    <legend className={classNames({ [styles.cardQuestionTitle]: conditionQuestion.subText })}>
                        {conditionQuestion.question}
                    </legend>
                    {conditionQuestion.subText && (
                        <legend className={styles.cardSubtext}>{conditionQuestion.subText}</legend>
                    )}
                    {conditionQuestion.additionalContentType && getAdditionalQuestionTemplateContent(conditionQuestion)}
                </ValidatedArea>
                <div>{choices}</div>
            </fieldset>
        </>
    );
};

export default CardQuestion;

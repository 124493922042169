import * as React from 'react';
import * as styles from './KbbValuation.module.scss';
import { formatDate } from '../../utils/format';
import { useOfferContext } from '../../context/offerContext';
import classNames from 'classnames';

const KbbValuation: React.FC = () => {
    const { offer, kbb } = useOfferContext();
    const [iframeSrc, setIframeSrc] = React.useState(kbb.priceAdvisorUrl);

    React.useEffect(() => {
        const url = new URL(iframeSrc);
        // Strip spaces from condition (i.e. Very Good = VeryGood)
        const conditionParam = kbb.customerSelectedConditionLevel.replace(/\s/g, '');
        url.searchParams.set('Condition', conditionParam);
        setIframeSrc(url.toString());
    }, [kbb.customerSelectedConditionLevel, iframeSrc]);

    return (
        <div className={classNames(styles.kbbValuationContainer, styles.kbbOfferPesentationTest)}>
            <div className={styles.kbbWidgetAndDate}>
                <h3 className={styles.compareToKbb}>Compare our offer</h3>
                <iframe
                    src={iframeSrc}
                    className={styles.kbbWidgetFrame}
                    width={300}
                    height={385}
                    aria-label="KBB Valuation"
                />
                <div className={styles.kbbGenerationDate}>
                    Generated on {formatDate(offer.createdDateUtc, 'mm/dd/yyyy')}
                </div>
            </div>
        </div>
    );
};

export default KbbValuation;

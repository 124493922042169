import * as React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import ICOStepper from './ICOStepper';
import { IVehicleInfo } from '../types/IVehicleInfo';
import Ineligible from './Ineligible';
import LicenseOrVin from './LicenseOrVin/LicenseOrVin';
import MuiLicenseOrVin from './LicenseOrVin/MuiLicenseOrVin';
import Offer from './Offer/Offer';
import IneligiblePhotoCapture from './IneligiblePhotoCapture';
import { icoStart, initialFormSubmitted } from '../utils/offerEvents';
import { IOffer } from '../types/IOfferResponse';
import { MuiTestNames, testNames, useFeatures } from '../context/features';
import OfferDisclaimer from './OfferDisclaimer';
import { trackICOInit, trackICOSubmit } from '../utils/analytics';
import { profileComplete } from '../utils/validation';
import { IVinEligibility } from '../types/IVinEligibility';
import { IVinMatchingStylesEligibility } from '../types/IVinMatchingStyles';
import LoadingScreen from './LoadingScreen';
import { CLIENT_INELIGIBLE_ERRORS } from '../constants';
import { useVehicleInfo, vehicleInfoInitialState } from '../context/vehicleInfo';
import { useFormContext } from '../context/formContext';
import { useOfferContext } from '../context/offerContext';
import { IcoStartingMethod } from '../types/IFormMetadata';
import useDecodeVehicle from '../hooks/events/useDecodeVehicle';
import useStartQuote from '../hooks/events/useStartQuote';
import useTriggerIneligibleEvent from '../hooks/events/useTriggerIneligibleEvent';
import { getQuoteV2 } from '../api/quote';
import OfferRefresh from './OfferRefresh/OfferRefresh';
import { useCustomerInfo } from '../context/customerInfo';
import { formatSeperatedInteger } from '../utils/format';
import { AVAILABLE_DRIVES, AVAILABLE_TRANSMISSIONS } from './Steps/FeaturesBody/data';
import { IQuote } from '../types/IQuote';
import useRequestOffer from '../hooks/events/useRequestOffer';
import ActiveStoreAppraisal from './ActiveStoreAppraisal/ActiveStoreAppraisal';
import SimplifiedIneligiblePhotoCapture from './SimplifiedIneligiblePhotoCapture';
import { isMobile } from 'react-device-detect';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { getCookieValue } from '../utils/cookie';
import { portalRoot } from './Portal/Portal';

interface IICOProps {
    offerId: string;
    originPage?: string;
    reset: () => void;
    startQuote?: IVinEligibility;
    enabledFeatures: string[];
    storeId?: string;
    vehicleStyles?: IVinMatchingStylesEligibility;
}

const InstantCashOffer: React.FC<IICOProps> = props => {
    const appInsights = useAppInsightsContext();
    const effectHasRun = useRef(false);
    const { setCustomerInfo } = useCustomerInfo();
    const [icoStarted, setIcoStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { start: startQuote } = useStartQuote(props);
    const { start: startDecodeVehicle } = useDecodeVehicle();
    const { startSSE: requestQuoteSSE } = useRequestOffer();
    const { trigger: vehicleIneligible } = useTriggerIneligibleEvent();
    const { vehicleInfo, setVehicleInfo, setVehicleConditionInfo, setFeatureInfo } = useVehicleInfo();
    const { formMetadata } = useFormContext();
    const { quoteId, offer, ineligible, isIneligible, storeAppraisal, clear } = useOfferContext();
    const { killswitch } = useFeatures();
    const { isFeatureEnabled } = useFeatures();
    const [refreshOfferChanges, setRefreshOfferchanges] = useState(null);
    const [previousOffer, setPreviousOffer] = useState(null);

    const isPicsyEligible = useCallback(
        (offer: IOffer) => quoteId && offer?.isPicsyEligible && isFeatureEnabled(testNames.PHOTO_CAPTURE),
        [quoteId, isFeatureEnabled]
    );

    const getIneligiblePageAnalyticsValue = useCallback(
        (offer: IOffer) => (isPicsyEligible(offer) ? 'icoineligible-picsyenabled' : 'icoineligible'),
        [isPicsyEligible]
    );

    const startIco = useCallback(
        async (vehicleData: IVehicleInfo) => {
            try {
                const pageType = getIneligiblePageAnalyticsValue(offer);

                const res = await startQuote({ vehicleData, pageType });

                if (res?.isSuccess) {
                    setIcoStarted(true);
                    trackICOInit(props.originPage, vehicleInfo.vin);
                    icoStart();
                }
            } catch (err) {
                if (err?.response?.status != 401) {
                    console.log(err);
                }

                vehicleIneligible({
                    reason: CLIENT_INELIGIBLE_ERRORS.errorClientSide,
                    pageType: getIneligiblePageAnalyticsValue(offer),
                    isPicsyEligible: false, //should this be true?
                });
            }
        },
        [vehicleIneligible, vehicleInfo, startQuote, getIneligiblePageAnalyticsValue, offer, props.originPage]
    );

    const decodeVehicleInfo = useCallback(
        async (startingMethod: IcoStartingMethod, setLoading = true) => {
            setIsLoading(true);

            const resp = await startDecodeVehicle({
                startingMethod,
                pageType: getIneligiblePageAnalyticsValue(offer),
            });

            if (resp) {
                await startIco(resp);
            }

            if (setLoading) {
                setIsLoading(false);
            }
        },
        [startIco, getIneligiblePageAnalyticsValue, startDecodeVehicle, offer]
    );

    const getStartedClick = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            initialFormSubmitted(vehicleInfo.vin, vehicleInfo.plate, vehicleInfo.state, vehicleInfo.zipcode);
            appInsights.trackEvent({
                name: 'GetStarted - clicked',
                properties: {
                    vin: vehicleInfo.vin || undefined,
                    plate: vehicleInfo.plate || undefined,
                },
            });

            await decodeVehicleInfo(formMetadata.startingMethod);
        },
        [decodeVehicleInfo, formMetadata]
    );

    const editVehicleClick = useCallback(async () => {
        clear();
        setIcoStarted(false);
        await decodeVehicleInfo('VIN');
    }, [decodeVehicleInfo, formMetadata]);

    const parseAnswersForPreviousOffer = (quote: IQuote) => {
        setPreviousOffer(quote);
        setCustomerInfo({
            email: quote.metaData.emailAddress,
        });
        setVehicleConditionInfo(prev => ({
            ...prev,
            mileage: formatSeperatedInteger(quote.mileage),
            conditionAnswers: quote.conditionQuestions,
        }));
        setVehicleInfo(prev => ({ ...prev, isComplete: true, vin: quote.vin, zipcode: quote.zipCode }));
        setFeatureInfo(prev => ({
            ...prev,
            isComplete: false,
            drive: AVAILABLE_DRIVES.find(x => x.code === quote.drive)?.value || null,
            transmission: AVAILABLE_TRANSMISSIONS.find(x => x.value === quote.transmission)?.value || null,
            availableOptions: quote.availableOptions,
            standardOptions: quote.standardOptions,
            style: { id: quote.styleCode, description: null },
        }));
    };

    useEffect(() => {
        if (effectHasRun.current) {
            return;
        }

        (async function initIco() {
            effectHasRun.current = true;
            if (profileComplete(vehicleInfo)) {
                await startIco(vehicleInfo);
            } else if (props.offerId) {
                //offer refresh case
                setIsLoading(true);
                try {
                    const quote = await getQuoteV2(props.offerId, false);
                    if (quote.data && quote.data.offer) {
                        parseAnswersForPreviousOffer(quote.data.offer);
                    } else if (vehicleInfo.zipcode && vehicleInfo.vin) {
                        // old offer is older than 45 days, just start with VIN
                        await decodeVehicleInfo('VIN');
                    } else {
                        //the client didn't give us vin/zip, just reset
                        props.reset();
                    }
                } catch {
                    props.reset();
                }
            } else if (vehicleInfo.zipcode && (vehicleInfo.vin || (vehicleInfo.plate && vehicleInfo.state))) {
                await decodeVehicleInfo(vehicleInfo.vin ? 'VIN' : 'LP');
            } else {
                setVehicleInfo(vehicleInfoInitialState);
            }
        })();
    }, [startIco, vehicleInfo, decodeVehicleInfo, setVehicleInfo, props.offerId]);

    //Offer refresh question trigger -
    //if changes, decode the vehicle and do the normal process
    //if no changes, decode and start quote
    useEffect(() => {
        if (refreshOfferChanges === false) {
            setIsLoading(true);
            (async () => {
                const resp = await startDecodeVehicle({
                    startingMethod: 'VIN',
                    pageType: getIneligiblePageAnalyticsValue(offer),
                });
                await startQuote({ vehicleData: resp, pageType: getIneligiblePageAnalyticsValue(offer) });
            })();
        } else if (refreshOfferChanges === true) {
            (async () => {
                await decodeVehicleInfo('VIN', refreshOfferChanges);
            })();
        }
    }, [refreshOfferChanges]);

    //offer refresh, no changes, and start quote completed (we have quoteId)
    useEffect(() => {
        if (refreshOfferChanges === false && quoteId) {
            (async () => {
                trackICOSubmit(props.originPage, vehicleInfo.vin, quoteId);
                await requestQuoteSSE({ getIneligiblePageAnalyticsValue });
            })();
        }
    }, [refreshOfferChanges, quoteId]);

    //only for offer refresh no changes once the offer comes back (similar to the stepper)
    //But it's okay to not check the case because icoStarted will already be true for the other cases
    //and loading should be false if we have an offer back (or ineligible)
    useEffect(() => {
        if (offer || ineligible) {
            setIcoStarted(true);
            setIsLoading(false);
        }
    }, [offer, ineligible]);

    //this should already be false except for the offer refresh case with no changes
    useEffect(() => {
        if (offer || isIneligible) {
            setIsLoading(false);
        }
    }, [offer, isIneligible]);

    useEffect(() => {
        if (window?.FS) {
            window?.FS?.setUserVars({
                instantOfferVersion: 'v2',
            });
        }
    }, []);

    const isMuiEnabled = MuiTestNames.some(muiTest => isFeatureEnabled(muiTest));

    return (
        <div id="ico">
            {!killswitch && !icoStarted && !ineligible && isMuiEnabled && (
                <div id="license-or-vin">
                    <MuiLicenseOrVin onSubmit={getStartedClick} readOnly={isLoading} />
                </div>
            )}
            <div className="kmx-ico-component-root">
                {/* First loading screen */}
                {isLoading && <LoadingScreen />}

                {/* Offer refresh */}
                {!killswitch && !offer && previousOffer && !icoStarted && refreshOfferChanges === null && (
                    <OfferRefresh
                        setRefreshOfferchanges={setRefreshOfferchanges}
                        reset={props.reset}
                        previousOffer={previousOffer}
                    />
                )}

                {/* Vehicle info entry  */}
                {!killswitch && !icoStarted && !ineligible && !isMuiEnabled && (
                    <div id="license-or-vin">
                        <LicenseOrVin onSubmit={getStartedClick} readOnly={isLoading} />
                    </div>
                )}

                {/* Offer/ineligible (above ICO stepper so stepper can be on the offer page)  */}
                <div id="offer-and-ineligible">
                    {offer?.valuation > 0 && (
                        <Offer
                            vin={vehicleInfo.vin}
                            originPage={props.originPage}
                            userId={getCookieValue('KmxMyKmx_0', 'userid')}
                            storeId={props.storeId}
                            onEditVehicleClick={editVehicleClick}
                        />
                    )}
                    {(isIneligible || killswitch) &&
                        (isPicsyEligible(offer) ? (
                            isMobile && isFeatureEnabled(testNames.SIMPLIFIED_INELIGIBLE_IO) ? (
                                <SimplifiedIneligiblePhotoCapture />
                            ) : (
                                <IneligiblePhotoCapture />
                            )
                        ) : (
                            <Ineligible profile={vehicleInfo.profile} changeVehicle={props.reset} />
                        ))}
                    {/* Active store appraisal offer page */}
                    {storeAppraisal && (
                        <div>
                            <ActiveStoreAppraisal reset={props.reset} originPage={props.originPage} />
                        </div>
                    )}
                </div>

                {/* ICO stepper - vehicle profile, style/feature selection, condition info, etc. */}
                {!killswitch && icoStarted && !isIneligible && !storeAppraisal && (
                    <div id="ico-stepper">
                        <ICOStepper
                            originPage={props.originPage}
                            getIneligiblePageAnalyticsValue={getIneligiblePageAnalyticsValue}
                            previousOffer={previousOffer}
                            refreshOfferChanges={refreshOfferChanges}
                            onEditVehicleClick={editVehicleClick}
                        />
                    </div>
                )}

                {offer?.valuation > 0 && <OfferDisclaimer />}
                <div id={portalRoot}></div>
            </div>
        </div>
    );
};

export default InstantCashOffer;

import { AdditionalConditionQuestionContentType, IConditionQuestion } from './IConditionQuestion';

// export const QUESTION_EDMUNDS_TMV: IConditionQuestion = {
//     questionId: 1010,
//     question: 'What is the overall condition of your vehicle?',
//     subText: 'This will help us provide you with an edmunds.com trade-in value to compare with our offer.',
//     selectedAnswerId: 3,
//     answers: [
//         {
//             questionId: 1010,
//             answerId: 1,
//             answer: 'Outstanding',
//             subText:
//                 'Exceptional mechanical, exterior and interior condition with no visible wear; no reconditioning required.',
//             note: 'Approximately 5% of used vehicles qualify',
//         },
//         {
//             questionId: 1010,
//             answerId: 2,
//             answer: 'Clean',
//             subText:
//                 'Minimal wear and tear with no major mechanical or cosmetic problems; may require limited reconditioning.',
//         },
//         {
//             questionId: 1010,
//             answerId: 3,
//             answer: 'Average',
//             subText:
//                 'Normal wear and tear. May have a few mechanical and/or cosmetic problems and may require some reconditioning.',
//         },
//         {
//             questionId: 1010,
//             answerId: 4,
//             answer: 'Rough',
//             subText: 'Several mechanical and/or cosmetic problems requiring repairs.',
//         },
//         {
//             questionId: 1010,
//             answerId: 5,
//             answer: 'Damaged',
//             subText: 'Major mechanical and/or body damage that may render it in non-safe running condition.',
//         },
//     ],
// };

export const QUESTION_KBB_CONDITION: IConditionQuestion = {
    questionId: 1020,
    question: 'What is the overall condition of your vehicle?',
    subText: 'This will help us provide you with a Kelley Blue Book® Trade-in Range to compare with our offer.',
    selectedAnswerId: 4,
    answers: [
        {
            questionId: 1020,
            answerId: 5,
            answer: 'Excellent',
            subText: '(2% of cars KBB values) - Looks new and is in excellent mechanical condition',
        },
        {
            questionId: 1020,
            answerId: 109,
            answer: 'Very good',
            subText: '(28% of cars KBB values) - Has minor cosmetic defects and is in good mechanical condition',
        },
        {
            questionId: 1020,
            answerId: 4,
            answer: 'Good',
            subText: '(50% of cars KBB values) - Has repairable cosmetic defects and mechanical problems',
        },
        {
            questionId: 1020,
            answerId: 3,
            answer: 'Fair',
            subText: '(20% of cars KBB values) - Requires some mechanical repairs',
        },
    ],
    additionalContentType: AdditionalConditionQuestionContentType.KbbCondition,
};
